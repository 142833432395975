﻿
.only4ie {
    /* ################################################ */
    /* ##### ANIMATIONS ############ */
    /* ################################################ */
    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: scale(0.95);
        }

        to {
            opacity: 1;
            transform: scale(1.0);
        }
    }
    
    section.on {
        
        .animate-fade-in {
            opacity: 0;
            animation: fadeIn 1.5s forwards;
        }

         .animate-delay-8 {
            animation-delay: 7s;
        }

        .animate-delay-9 {
            animation-delay: 8s;
        }
    }


    svg#brain {
        // triangles
        polygon {
            fill-opacity: 1;
        }
        // strokes
        path {
            stroke-dasharray: 0px;
            stroke-dashoffset: 0px;
        }

        circle {
            opacity: 0;
            animation: fadeIn 2s forwards;
        }
    }
}
