@media only screen and (max-width: 1500px) {
.svgresponsive{
    left: 50% !important;
    right: auto !important;
    transform: translateX(-50%) !important;
    }

}

@media only screen and (max-width: 1300px) {
  .navbar-brand .logo {
    /*margin-left: 0 !important;*/
  }
    .svgresponsive{
        display: none !important;
    }
    svg.overview{
        left: 120px !important;
    } 
}

@media only screen and (max-width: 1199px) {
    .navbar{
        display: block;
        .container{
            display: block;
        }
        .navbar-nav{
          margin-top: 8px;
          margin-bottom: 8px;
        }
    }
    .navbar-nav{
        margin-left: 0!important;
        float: right;
    }
    .svg-brain-container{
        width: 100%!important;
        #brain{
            width: 100% !important;
        }
    }
  .navbar-expand-lg .navbar-collapse {
    float: right;
    /*padding-top: 30px;*/
  }

    #home{
        padding-bottom: 60px;
    }
    svg.overview{
        left: 55px !important;
    }
}

@media only screen and (max-width: 1100px) {
    #home .text-padding {
        padding: 36px 66px!important;
    }
    #features .cog-box{
        margin-top: 70px !important;
    }
}



@media only screen and (max-width: 1023px) {
    .svg-brain-container #brain{
        width: 800px !important;
    }
    #home {
        margin-top: -521px !important;
    }
    svg.paralax{
        right: 12px !important;
    }
    #about .text-content{
        margin-top: 63px !important;
    }
    svg.features{
        top: -125px;
    }
    svg.features{
        left: 50px!important;
        top: -127px!important;
    }
    #features .icon-list li{
        margin: 1rem 0 0 0 !important;
    }
    a.js-play:after{
        right: 9%;
        bottom: 45px;
    }

    #features .cog-box{
        margin-top: 177px !important;
    }
    #stroke-page-canvas{
        display: none !important;
    }
    
} 

@media only screen and (max-width:991px) {
  .navbar-toggler {
    float: right;
    /*margin-top: 28px;*/
  }
    .navbar-expand-lg .navbar-collapse{
        width: 100%;
    }
    .navbar-expand-lg .navbar-collapse ul{
        float: none;
    }
}

@media only screen and (max-width:640px) {
 #CONNECTIONS, .paralax.collaboration-2.d-lg-none{
     display: none!important;
 }
 .paralax.overview.zindexupper polygon, .paralax.overview.zindexupper path[stroke-linecap^="square"], .paralax.overview.zindexupper circle{
     display: none!important;
 }
 svg.overview{
     width: 280px !important;
     margin: auto!important;
     left: 0px!important;
     top: -174px !important;
 }
 #home .text-padding{
     padding:0 0 0 15px !important;
 }
 #home{
     margin-top: -632px!important;
 }
 #about{
     padding-top: 25px !important;
 }
 #about .col-lg-5.col-4{
    display: none;
 }
 #about .col-lg-7.col-8{
    max-width: 100% !important;
    padding-left: 15px !important;
    flex: auto;
    padding-top: 50px !important;
 }
 #vimeo-iframe-container{
     height: 300px;
 }
 #about .text-content{
    margin-top: 0px !important;
}
svg.paralax{
    width: 320px;
}
svg.features{
    top:-65px!important;
    left: 20px !important;
}

a.js-play:after{
    right: auto;
    left: 80%;
    bottom: 19px;
}
#features .icon-list li{
    margin-bottom: 40px !important
}
#features .cog-box{
    margin-top: 64px!important;
}
#features{
    padding-top: 35px!important;
}
#features{
    height:557px !important ;
}
// .not4ie section.on .animate-fade-in{
//     flex: auto;
//     width: 100%;
//     max-width: 100%;
// }

#customers .col-8.text-left.d-lg-none.animate-fade-in{
    flex: auto!important;
    width: 100%;
    max-width: 100% !important;
}
svg.planet{
    display: none !important;
}
#about{
    margin-top: -75px !important;
    padding-top: 0 !important;
}
svg.collaboration{
    left: -120px !important;
    top: -19px!important;
}
.navbar-expand-lg .navbar-collapse{
    padding-top: 0 !important;
}
}
