﻿
.not4ie {
    /* ################################################ */
    /* ##### ANIMATIONS ############ */
    /* ################################################ */
    @keyframes fadeInDots {
        0% {
            opacity: 0
        }

        25% {
            opacity: 0.75
        }

        75% {
            opacity: 1
        }

        100% {
            opacity: 1
        }
    }

    @keyframes fadeInTriangles {
        0% {
            fill-opacity: 0
        }

        25% {
            fill-opacity: 0.25
        }

        75% {
            fill-opacity: 0.75
        }

        100% {
            fill-opacity: 1
        }
    }

    @keyframes fadeInStrokes {
        from {
            stroke-dashoffset: 1000px;
        }

        to {
            /*opacity: 1;*/
            stroke-dashoffset: 0;
        }
    }


    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: scale(0.95);
        }

        to {
            opacity: 1;
            transform: scale(1.0);
        }
    }

    section.on {
        
        .animate-fade-in {
            opacity: 0;
            animation: fadeIn 0.7s forwards;
        }

         .animate-delay-8 {
            animation-delay: 3.5s;
        }

        .animate-delay-9 {
            animation-delay: 4s;
        }
    }

    /*.animate-fade-in {
        opacity: 0;


    }*/

   


    svg#brain {
        // triangles
        polygon {
            fill-opacity: 0;
            animation: fadeInTriangles 1s forwards;
            animation-delay: 2s;
            // the big dot
            &#Rectangle-10 {
                animation: fadeInTriangles 0.5s forwards;
                animation-delay: 2.5s;
            }
        }
        // strokes
        path {
            stroke-dasharray: 1000px;
            stroke-dashoffset: 1000px;
            animation: fadeInStrokes 3.5s forwards;

            &:nth-child(odd) {
                animation-delay: 1.5s;
            }

            &:nth-child(even) {
                animation-delay: 1.25s;
            }
        }

        circle {
            opacity: 0;
            animation: fadeInDots 3s forwards;

            @for $i from 1 through 200 {
                &:nth-child(#{$i}) {
                    animation-delay: (0.01s * $i);
                }
            }
            // the big dot
            &#Oval-4, &#Oval-5 {
                animation: fadeInDots 2.5s forwards;
                animation-delay: 1s;
            }
        }
    }

    svg#brain-mobile {
        width: 375px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        // triangles
        polygon {
            fill-opacity: 0;
            animation: fadeInTriangles 1s forwards;
            animation-delay: 6s;
            // the big dot
            &#Rectangle-10 {
                animation: fadeInTriangles 0.5s forwards;
                animation-delay: 3.5s;
            }
        }
        // strokes
        path {
            stroke-dasharray: 1000px;
            stroke-dashoffset: 1000px;
            animation: fadeInStrokes 10s forwards;
            /*animation-delay: 5s;*/
            &:nth-child(odd) {
                animation-delay: 2.5s;
            }

            &:nth-child(even) {
                animation-delay: 3s;
            }
        }

        ellipse {
            opacity: 0;
            animation: fadeInDots 2s forwards;

            @for $i from 1 through 72 {
                &:nth-child(#{$i}) {
                    -webkit-animation-delay: (0.05s * $i);
                    -moz-animation-delay: (0.05s * $i);
                    animation-delay: (0.05s * $i);
                }
            }
            // the big dot
            &#Oval-4, &#Oval-5 {
                animation: fadeInDots 2.5s forwards;
                animation-delay: 1s;
            }
        }
    }
}
