@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates:300,500');

/*$break-xs: 320px;
$break-sm: 768px;
$break-md: 992px;
$break-lg: 1200px;*/

$transparent: rgba(255, 255, 255, 0);
$white: #fff;

html, body {
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight:300;
     color:#FFF;
	background: rgba(3,12,29,1);
    height:1500px;
    margin: 0;
    padding: 0;
}

html {
    overflow-x:hidden;
}

a {
    color: #0079b8;
}

button {
  cursor: pointer;
}

button:disabled{
  cursor: wait;
}

#page-top {
     /*background: linear-gradient(17.48deg, #19212B 0%, #060E1E 100%);*/ 
     background: #0a1221;
     border-right: 1px solid rgba(33,42,59,1);
     border-left: 1px solid rgba(33,42,59,1);
     max-width:1440px;
     margin: 0 auto;

     overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 300;
  line-height: 1.2;
  color: inherit;
}


.blend-fix {
    position: absolute;
    width: 1px;
    height: 1px;
    visibility: hidden;
    background: red;
    mix-blend-mode: overlay;
}

.blend-saturation {
    fill: #4eb9ff;
    mix-blend-mode: saturation;
}


.blend-saturation-dark {
    fill: #245a7d;
    mix-blend-mode: saturation;
}

.animate-fade-in {
    opacity: 0;
    
}
.modal-header {
    background:#000;
    border-bottom:none;
}
.modal-dialog{
  margin: 50px auto;
  width: 1000px;
  max-width:90%;
  min-width:300px;
}

.modal-body {
    position: relative;
    padding-bottom: 53.4%;
    padding-top: 30px;
    overflow: hidden;
}

#vimeo-iframe-container{
height: 500px;    
background: #000;
}
.modal-body iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*  Navbar */
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.95rem;
    padding-left: 0.95rem;
    font-size: 0.95rem;
    color: #0079B9;

    &.login {
        color: #FFF;
    }

    &.active {
        color: #0BB8FF;
    }
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler {
  border: none;
  margin-right: 10px;
  margin-top: 10px;

  .navbar-toggler-icon {
    height: 23px;
    width: 23px;
    /*background-image: url("data:image/svg+xml;charset=utf8,<svg width='15px' height='16px' viewBox='0 0 22 23' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs></defs><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'><g id='MOBILE-DROPDOWN' transform='translate(-322.000000, -19.000000)' stroke='#FFFFFF' stroke-width='4'><g id='DROPDOWN-MENU' transform='translate(-22.000000, 0.000000)'><g id='Group-3' transform='translate(346.000000, 21.000000)'><path d='M1,1 L16.7504185,17.7504185' id='Line-4'></path><path d='M0,18.6374294 L18,0.63742936 L0,18.6374294 Z' id='Line-4'></path></g></g></g></g></svg>");*/
  }

    &.collapsed {


      .navbar-toggler-icon {
        height: 25px;
        width: 25px;
        /*background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");*/
        /*background-image: url("data:image/svg+xml;charset=utf8,<svg width='31px' height='19px' viewBox='0 0 31 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='square'><g id='MOBILE-VERSION' transform='translate(-317.000000, -21.000000)' stroke='#FFFFFF' stroke-width='3'><g id='Group' transform='translate(319.000000, 21.000000)'><path d='M0.397058824,1.5 L26.6029412,1.5' id='Line'></path><path d='M0.397058824,9.5 L26.6029412,9.5' id='Line'></path><path d='M0.397058824,17.5 L26.6029412,17.5' id='Line'></path></g></g></g></svg>");*/
      }
    }
  }


  .nav-link {
    text-transform: uppercase;
    font-weight: 400;
    display: block;
    padding: .5rem 1rem;
    color: #0079b8;

    &.login {
      color: #FFF;

      img {
        height: 16px;
        margin: 0 0 0 4px;
        vertical-align: middle;
      }
    }
  }

  .navbar-collapse {
    background: rgba(3,12,29,0.92);
    /*background-image: url(/img/bg-dropdown.svg)*/
  }

  .navbar-nav {
    margin-left: 10px;
    text-align: left;
  }

  .navbar {
    padding: 0;
  }

  .navbar:after {
    height: 100px;
    background: rgba(10, 18, 33, 0);
    transition: .5s background ease-out;
    top: 0;
    transform: skewY(2.5deg);
    transform-origin: 100%;
    height: 110px;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    z-index: -1;
    -webkit-backface-visibility: hidden;
  }

  .navbar.stick {
    height: 100px;

    &:after {
      background: rgba(3,12,29,1);
      border-bottom: 1px solid rgba(33,42,59,1);
      /*top: 0;
            transform: skewY(2.5deg);
            transform-origin: 100%;
            height:110px;
            content: '';
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
            -webkit-backface-visibility: hidden;*/
    }
  }


  @mixin angle($pseudo, $flip: false, $angle: 2.1deg) {
    @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
      position: relative;
      /*z-index: 1;*/
      $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

      #{$selector} {
        background: inherit;
        content: '';
        display: block;
        height: 140px;
        left: 0;
        position: absolute;
        right: 0;
        z-index: 1;
        /*-webkit-backface-visibility: hidden;*/ // for Chrome Windows
      }

      @if $pseudo == 'before' {
        #{$selector} {
          top: 0;
          background: #0a1221;

          @if $flip {
            transform: skewY($angle * -1);
            transform-origin: 0 0;
          }

          @else {
            transform: skewY($angle);
            transform-origin: 100% 0;
          }
        }
      }

      @if $pseudo == 'after' {
        #{$selector} {
          bottom: 0;

          @if $flip {
            transform: skewY($angle);
            transform-origin: 0 100%;
          }
          @else {
            transform: skewY($angle * -1);
            transform-origin: 100%;
          }
        }
      }

      @if $pseudo == 'both' {
        &:before {
          top: 0;
          background: #0a1221;

          @if $flip {
            transform: skewY($angle * -1);
            transform-origin: 0 0;
          }
          @else {
            transform: skewY($angle);
            transform-origin: 100% 0;
          }
        }

        &:after {
          bottom: 0;

          @if $flip {
            transform: skewY($angle);
            transform-origin: 0 0;
          }
          @else {
            transform: skewY($angle * -1);
            transform-origin: 100%;
          }
        }
      }
    }
  }
  // slanted edges here
  .edge--bottom {
    @include angle(after);

    .container {
      z-index: 10;
      position: relative;
    }

    &.gradient:after {
      background: -moz-linear-gradient(top, #0a1221 0%, #1b222c 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #0a1221, 0%,#1b222c 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #0a1221 0%,#1b222c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a1221', endColorstr='#1b222c',GradientType=0 ); /* IE6-9 */
    }
  }

  .edge--bottom--reverse {
    @include angle(after, true);
    z-index: -10;

    &.gradient:after {
      background: -moz-linear-gradient(top, #0a1221 0%, #1b222c 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #0a1221, 0%,#1b222c 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #0a1221 0%,#1b222c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a1221', endColorstr='#1b222c',GradientType=0 ); /* IE6-9 */
    }
  }

  .edge--top {
    @include angle(before);

    .container {
      z-index: 10;
      position: relative;
    }
  }

  .edge--top--reverse {
    @include angle(before, true);
  }

  .edge--both {
    @include angle(both);

    .container {
      z-index: 10;
      position: relative;
    }

    &.gradient:after {
      background: -moz-linear-gradient(top, #0a1221 0%, #1b222c 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #0a1221, 0%,#1b222c 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #0a1221 0%,#1b222c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a1221', endColorstr='#1b222c',GradientType=0 ); /* IE6-9 */
    }
  }

  .edge--both--reverse {
    @include angle(both, true);

    &.gradient:after {
      background: -moz-linear-gradient(top, #0a1221 0%, #1b222c 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #0a1221, 0%,#1b222c 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #0a1221 0%,#1b222c 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a1221', endColorstr='#1b222c',GradientType=0 ); /* IE6-9 */
    }
  }

  .block {
    background-color: #0a1221;
    color: #fff;
    padding: 5% 0;
    text-align: center;

    &.image {
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      .container {
        z-index: 10;
        position: relative;
      }
      /*&:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(20, 28, 40, 0.8);
        }*/
    }

    &.fill-color, &.fill-color:before, &.fill-color:after {
      background: #1e252f;
    }
  }
  /* SMARTPHONE, TABLET */
  @media only screen and (min-width : 320px) {


    section {
      padding: 150px 0;
      font-size: 0.8rem;
    }

    footer {
      margin-top: -100px;
      position: relative;
      font-weight: 500;
      font-size: 14px;

      .container-fluid {
        background: #010A1A;
        padding: 50px 0 50px 0;
      }
    }

    h1 {
      font-size: 1.8rem;
    }

    h4 {
      font-size: 1.2rem;
    }

    .lead {
      font-size: 0.75rem;
    }

    .logo {
      max-width: 120px;
      margin: 0px;
      position: relative;
      top: -2px;
      left: 10px;
    }

    .fixed-background {
      /*background-attachment: scroll;*/
      /*background-attachment:fixed;*/
      /*background-color:transparent;
            position:relative;*/
      /*will-change: transform;*/
      /*will-change: transform;*/
      // Fixed-position background image
      /*&::before {
                content: ' ';
                position: fixed; // instead of background-attachment
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: url(/img/bg_features.png);
                background-size: cover;
                will-change: transform; // creates a new paint layer
                z-index: -1;
              }*/
    }
  }
  /* DESKTOP */
  @media only screen and (min-width : 1024px) {
    section {
      font-size: 1rem;
    }

    .navbar {
      padding: 0;
      padding-top: 6px;

      .container {
        width: 1024px;
      }
    }

    footer {
      position: relative;
      font-weight: 500;
      font-size: 14px;

      .container-fluid {
        background: #010A1A;
        padding: 50px 0 50px 0;
      }
    }

    h1 {
      font-size: 2.5rem;
    }

    .lead {
      font-size: 1.25rem;
    }

    .logo {
      max-width: 120px;
      /*margin: 0 0 0 40px;*/
    }

    .navbar-collapse {
      background: none;
    }

    .navbar-nav {
      margin-left: 110px;
      text-align: right;
    }

    .navbar:after {
      height: 150px;
    }

    .navbar.stick {
      height: 100px;

      &:after {
        height: 150px;
      }
    }
  }

  a.js-play:after {
    position: absolute;
    content: "";
    height: 7px;
    width: 7px;
    background: #fff;
    border-radius: 50%;
    right: 22%;
    bottom: -7px;
  }

  #about .text-content img {
    margin-bottom: -12px;
  }
