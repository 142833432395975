﻿@import "global.scss";
@import "not4ie.scss";
@import "only4ie.scss";
@import "responsive.scss";

    svg#brain, svg#brain-mobile {
        // triangles
        polygon {
            fill-opacity: 0;
        }
        // strokes
        path {
            stroke-dasharray: 1000px;
            stroke-dashoffset: 1000px;
        }

        circle {
            opacity: 0;
        }
    }

    svg#brain {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 20px;
    }

    svg.paralax {
        position: absolute;
        top: 0px;
        right: 0;
        bottom: 0;
        z-index: 1;
        /*width:90%;*/
        /*width:90%;*/
        margin: 0 auto;
        overflow: visible;

        polygon {
            fill-opacity: 0;
        }

        path, polyline {
            fill: none;
            stroke-dasharray: 1000px;
            stroke-dashoffset: 1000px;
            transition: 2.5s stroke-dashoffset ease-in;
        }

        &.on {
            path, polyline {
                stroke-dashoffset: 0;
            }

            polygon {
                animation: fadeInTriangles 2s forwards;
                animation-delay: 1s;
            }
        }
    }
    /* SMARTPHONE, TABLET */
    @media only screen and (min-width : 320px) {


        svg.overview {
            left: 25px;
            top: -30px;
            width: 337px;
        }

        svg.features {
            left: 20px;
            top: 0px;
        }

        svg.collaboration {
            left: -125px;
            top: -18px;
        }

        svg.collaboration-2 {
            left: -50px;
            top: 380px;
        }


        svg.planet {
            top: 0px;
            z-index: 2;
            left: -60px;
        }

        .svg-brain-container {
            margin-right: auto;
            padding-top: 100px;
            overflow-x: hidden;
        }

        html[data-useragent*='Trident'] {
            svg.paralax {
                path, polyline {
                    stroke-dashoffset: 0;
                    transition: none;
                }
            }
        }
       
    }
    /* DESKTOP */
    @media only screen and (min-width : 1024px) {
       


        svg.paralax {
            /*width:503px;*/
        }

        svg.features {
            left: 75px;
            top: -32px;
        }

        svg.overview {
            left: 60px;
            top: -90px;
            width: 502px;
        }

        svg.collaboration {
            left: -340px;
            top: -10px;
        }

        svg.planet {
            top: 25px;
            z-index: 2;
            left: 208px;
        }

        .svg-brain-container {
            width: auto;
            margin-left: auto;
            margin-right: auto;
            padding-top: 100px;
        }

        .svg-brain-container {
            width: 1305px;
            padding-top: 0px;
            margin-left: auto;
        }

        svg#brain {
            width: 1305px;
            height: 900px;
        }
    }
    /* #################################### */
    /* ############# SECTIONS #############*/
    /* #################################### */
    /* SMARTPHONE, TABLET*/
    @media only screen and (min-width : 320px) {

        #home {
            margin-top: -650px;
        }

        #features {
            background-image: url(../img/bg_features.png);
            margin-top: -10px;
            padding-top: 80px;
            height: 650px;

            .icon-list li {
                margin: 1.4rem 0 2.85rem 0;
                font-size: 0.9rem;
            }


            .cog-box {
                margin-top: 50px;
                padding-left: 50px;
            }
        }

        #about {
            margin-top: -90px;
            padding-top: 100px;

            .container {
                position: relative;
                min-height: 250px;
            }

            .text-content {
                margin-top: 90px; /* 200 */

                img {
                    margin-top: 40px;
                }
            }
        }

        #customers {
            padding-bottom: 0;

            .customer-box-1 {
                margin-top: -20px;
                z-index: 1;
            }

            .customer-box-2 {
                margin-top: -20px;
                z-index: 1;
            }

            .customer-header {
                height: 120px;
                padding-top: 20px;
            }
        }

        #planet {
            padding-top: 0px;
            height: 380px;
            background-position: top center;
            background-image: url(../img/earth-image.jpg);
        }
    }
    /* DESKTOP */
    @media only screen and (min-width : 1024px) {
        #home {
            margin-top: -450px;

            .text-padding {
                padding: 45px 70px;
            }
        }

        #features {
            padding-top: 150px;
            height: 880px;

            .icon-list li {
                margin: 1.8rem 0 2rem 0;
                font-size: 0.9rem;
            }

            .cog-box {
                margin-top: 120px;
            }
        }

        #about {
            margin-top: -35px;
            padding-top: 140px;

            .container {
                position: relative;
                min-height: 350px;
            }

            .text-content {
                margin-top: 145px; /* 360 */

                img {
                    margin-top: 0px;
                }
            }
        }

        #customers {
            padding-top: 120px;
            padding-bottom: 0px;

            .customer-box-1 {
                margin-top: -90px;
                z-index: 1;
            }

            .customer-box-2 {
                margin-top: -90px;
                z-index: 1;
            }

            .customer-header {
                height: 165px;
            }
        }


        #planet {
            padding-top: 150px;
            height: 670px;
        }
    }


    .zindexupper{
        z-index: 105 !important; 
    }